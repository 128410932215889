import { useEffect, useState } from 'react';
import { RestApiClient } from '@shahadul-17/rest-api-client';
import JoditEditor from "jodit-react";

import Styles from "./CmsContent.module.scss";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";

const FIELD_PRIORITY_CHANGE_STEP = 1;
const JODIT_EDITOR_VISIBLE_BUTTONS = ['bold', 'italic', 'underline', 'paragraph'];
const JODIT_EDITOR_CONFIGURATION = {
  readonly: false,
  inline: false,
  toolbar: true,
  toolbarInline: false,
  toolbarInlineForSelection: false,
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: false,
  showPlaceholder: false,
  buttons: JODIT_EDITOR_VISIBLE_BUTTONS,
  buttonsSM: JODIT_EDITOR_VISIBLE_BUTTONS,
  buttonsMD: JODIT_EDITOR_VISIBLE_BUTTONS,
  buttonsXS: JODIT_EDITOR_VISIBLE_BUTTONS,
};

/**
 * Deletes a content module field by ID.
 * @param {String} dynamicFieldId ID of the field that shall be deleted.
 * @returns 
 */
const deleteFieldAsync = async dynamicFieldId => {
  const restApiClient = RestApiClient.getInstance();
  const response = await restApiClient.sendSmartRequestAsync({
    routeName: 'deleteDynamicField',
    data: { dynamicFieldId: dynamicFieldId, },
  });

  return response;
};

export const CmsContent = ({ contentId, fieldId, fieldPriority, fieldLabel, fieldType, fieldKey, fieldValue, onFieldMove, onValueChange, onSaveClick, onFieldEdit, onFieldDelete, }) => {
  let inputFile = undefined;
  const [priority, setPriority] = useState(fieldPriority);
  const [value, setValue] = useState(fieldValue);

  const onInputValueChangedAsync = async event => {
    // checks if 'onValueChange' is a function...
    if (typeof onValueChange !== 'function') { return; }

    const _value = event.target.value;

    setValue(_value);
    await onValueChange({
      contentId, fieldId, fieldPriority: priority, fieldLabel, fieldType, fieldKey, fieldValue: _value, event,
    });
  };

  const onInputFileChangedAsync = async event => {
    if (!event.target.files.length) { return; }

    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file);
    formData.append('tags', `CONTENT_IMAGE, CONTENT_ID: ${contentId}, FIELD_ID: ${fieldId}`);
    const response = await RestApiClient.getInstance()
      .sendSmartRequestAsync({
        routeName: 'uploadFiles',
        data: formData,
      });

    if (response.status !== 200) { return; }

    const _value = response.jsonData.data.files[0].url;

    setValue(_value);
    await onValueChange({
      contentId, fieldId, fieldPriority: priority, fieldLabel, fieldType, fieldKey, fieldValue: _value, event,
    });
  };

  const onFieldMoveUpButtonClickedAsync = async event => {
    if (typeof onFieldMove !== 'function') { return; }

    const _priority = priority - FIELD_PRIORITY_CHANGE_STEP;

    setPriority(_priority);
    await onFieldMove({
      contentId, fieldId, previousFieldPriority: priority, fieldPriority: _priority, moveDirection: 'UP', fieldLabel, fieldType, fieldKey, fieldValue: value, event,
    });
  };

  const onFieldMoveDownButtonClickedAsync = async event => {
    if (typeof onFieldMove !== 'function') { return; }

    const _priority = priority + FIELD_PRIORITY_CHANGE_STEP;

    setPriority(_priority);
    await onFieldMove({
      contentId, fieldId, previousFieldPriority: priority, fieldPriority: _priority, moveDirection: 'DOWN', fieldLabel, fieldType, fieldKey, fieldValue: value, event,
    });
  };

  const onSaveButtonClickedAsync = async event => {
    // checks if 'onSaveClick' is a function...
    if (typeof onSaveClick !== 'function') { return; }

    await onSaveClick({
      contentId, fieldId, fieldPriority: priority, fieldLabel, fieldType, fieldKey, fieldValue: value, event,
    });
  };

  const onFieldEditButtonClickedAsync = async event => {
    if (typeof onFieldEdit !== 'function') { return; }

    await onFieldEdit({ contentId, fieldId, fieldPriority: priority, fieldLabel, fieldKey, fieldType, event, });
  };

  const onFieldDeleteButtonClickedAsync = async event => {
    if (typeof onFieldDelete !== 'function') { return; }
    if (!window.confirm(`Are you sure you want to delete '${fieldLabel}'?`)) { return; }

    const deleteResponse = await deleteFieldAsync(fieldId);

    await onFieldDelete({ contentId, fieldId, fieldPriority: priority, fieldLabel, fieldKey, fieldType, event, response: deleteResponse, });
  };

  useEffect(() => {
    setValue(fieldValue);
    setPriority(fieldPriority);
  }, [fieldValue, fieldPriority]);

  return <>
    <div className={Styles.container}>
      <div className={Styles.main}>
        <div className={Styles.titleContainer}>
          <label htmlFor={fieldKey}>{fieldLabel}</label>
          <div className={Styles.iconContainer}>
            <IoMdArrowDropup className={Styles.dragIcon} onClick={onFieldMoveUpButtonClickedAsync}/>
            <IoMdArrowDropdown className={Styles.dragIcon} onClick={onFieldMoveDownButtonClickedAsync}/>
          </div>
          
        </div>
        
        {fieldType === 'NUMERIC' && <input type='number' name={fieldKey} value={value} onChange={onInputValueChangedAsync} />}
        {fieldType === 'SINGLELINE_TEXT' && <input type='text' name={fieldKey} value={value} onChange={onInputValueChangedAsync} />}
        {fieldType === 'MULTILINE_TEXT' && <textarea name={fieldKey} value={value} onChange={onInputValueChangedAsync} />}
        {fieldType === 'MULTILINE_RICH_TEXT' && <JoditEditor
          tabIndex={1} // tabIndex of textarea
          value={value}
          config={JODIT_EDITOR_CONFIGURATION}
          // preferred to use 'onBlur' to update the content for performance reasons...
          onBlur={async text => await onInputValueChangedAsync({
            target: { value: text, },
          })}
          /* onChange={async text => await onInputValueChangedAsync({
            target: { value: text, },
          })} */
        />}
        {fieldType === 'IMAGE_URL' && <div className={Styles.imgContainer}>
          <input type='text' name={fieldKey} value={value} onChange={onInputValueChangedAsync} />
          <input type='file' style={{ display: 'none' }} ref={_ref => inputFile = _ref} onChange={onInputFileChangedAsync} multiple={false} />
          <button className={Styles.btnBrowse} type='button' onClick={() => inputFile?.click()}>Browse</button>
        </div>}
        <div className={Styles.btnContainer}>
          <button className={Styles.btn} type='button' onClick={onFieldEditButtonClickedAsync}>Edit Field</button>
          <button className={Styles.btn} type='button' onClick={onFieldDeleteButtonClickedAsync}>Delete Field</button>
          <button className={Styles.btn} type='button' onClick={onSaveButtonClickedAsync}>Save</button>
        </div>
      </div>
    </div>
  </>
};
