import { RestApiClient } from '@shahadul-17/rest-api-client';
import { useEffect, useState } from 'react';
import { StringUtilities } from '../../../utilities';

/**
 * Creates or updates a module.
 * @param {String} name Name of the module.
 * @param {String} moduleId (Optional) Module ID to update an existing module.
 * @returns Returns result.
 */
const createOrUpdateModuleAsync = async (name, path, keyPrefix, moduleId = '') => {
  const restApiClient = RestApiClient.getInstance();
  const response = await restApiClient.sendSmartRequestAsync({
    routeName: moduleId.length ? 'updateContentModule' : 'createContentModule',
    data: {
      moduleId: moduleId,
      name: name,
      path: path,
      keyPrefix: keyPrefix,
    },
  });

  if (response.status !== 200) { return undefined; }

  return response.jsonData;
};

export const CmsCreateOrUpdateModule = ({ moduleId, moduleKey, moduleName, modulePath, onFormSubmit, onResponse, onCancel, }) => {
  // removes unnecessary whitespaces...
  const _moduleId = StringUtilities.getDefaultIfUndefinedOrNullOrEmpty(moduleId, undefined, true);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [keyPrefix, setKeyPrefix] = useState(moduleKey);
  const [name, setName] = useState(moduleName);
  const [path, setPath] = useState(modulePath);

  const onFormSubmittedAsync = async event => {
    event.preventDefault();
    setLoading(true);
    setMessage('');

    const formData = new FormData(event.target);

    // if 'onFormSubmit' is a function, we shall execute it...
    if (typeof onFormSubmit === 'function') {
      await onFormSubmit({
        moduleId: _moduleId, formData, event,
      });
    }

    createOrUpdateModuleAsync(name, path, keyPrefix, _moduleId)
      .then(async response => {
        setLoading(false);

        response && setMessage(`CMS module ${_moduleId.length ? 'update' : 'create'}d successfully.`);
        !response && setMessage(`An error occurred while ${_moduleId.length ? 'updating' : 'creating'} CMS module.`);

        // if 'onResponse' is a function, we shall execute it...
        if (typeof onResponse === 'function') {
          await onResponse({
            moduleId: _moduleId, formData, event, response
          });
        }
      });
  };

  useEffect(() => {
    setKeyPrefix(moduleKey);
    setName(moduleName);
    setPath(modulePath);
  }, [moduleKey, moduleName, modulePath,]);

  return <>
    <h1>{_moduleId.length ? 'Update' : 'Create'} CMS Module</h1>
    <div>
      {isLoading && <p>Please wait...</p>}
      {!isLoading && message.length && <p>{message}</p>}
      {!isLoading && <form autoComplete='off' onSubmit={onFormSubmittedAsync}>
        <label htmlFor='name'>Module Name</label><br />
        <input type='text' name='name' value={name ?? ''}
          placeholder='Enter a name for your module'
          onChange={event => setName(event.target.value)}
          required /><br />

        <label htmlFor='path'>Module Path</label><br />
        <input type='text' name='path' value={path ?? ''}
          placeholder='Enter a path for your module'
          onChange={event => setPath(event.target.value)}
          required /><br />

        <label htmlFor='keyPrefix'>Module Key Prefix</label><br />
        <input type='text' name='keyPrefix' value={keyPrefix ?? ''}
          placeholder='Enter a key prefix for your module'
          onChange={event => setKeyPrefix(event.target.value)} /><br />

        <button type='submit'>{_moduleId.length ? 'Update' : 'Create'}</button>
        {_moduleId.length > 0 && <button type='button' onClick={onCancel}>Cancel</button>}
      </form>}
    </div>
  </>;
};
