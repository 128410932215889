import { RestApiClient } from '@shahadul-17/rest-api-client';

/**
 * Deletes a module by ID.
 * @param {String} moduleId ID of the module that shall be deleted.
 * @returns 
 */
const deleteModuleAsync = async moduleId => {
  const restApiClient = RestApiClient.getInstance();
  const response = await restApiClient.sendSmartRequestAsync({
    routeName: 'deleteContentModule',
    data: { moduleId: moduleId, },
  });

  if (response.status !== 200) { return undefined; }

  return response.jsonData;
};

export const CmsContentModule = ({ moduleId, moduleKeyPrefix, moduleName, modulePath, onEdit, onDelete, }) => {

  const onEditButtonClickedAsync = async event => {
    if (typeof onEdit !== 'function') { return; }

    await onEdit({ moduleId, moduleName, event, });
  };

  const onDeleteButtonClickedAsync = async event => {
    if (typeof onDelete !== 'function') { return; }
    if (!window.confirm(`Are you sure you want to delete '${moduleName}'?`)) { return; }

    const deleteResponse = await deleteModuleAsync(moduleId);

    await onDelete({ moduleId, moduleName, event, response: deleteResponse, });
  };

  return <div key={moduleId}>
    <p key={moduleId}>{moduleName}</p>
    <button type='button' onClick={onEditButtonClickedAsync}>Edit</button>
    <button type='button' onClick={onDeleteButtonClickedAsync}>Delete</button>
    <br />
  </div>;
};
