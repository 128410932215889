import { RestApiClient } from '@shahadul-17/rest-api-client';
import { useEffect, useState } from 'react';
import { StringUtilities } from '../../../utilities';
import Styles from "./CmsCreateOrUpdateField.module.scss";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
/**
 * Creates or updates a module field.
 * @param {{
 * fieldId: String,
 * label: String,
 * inputType: String,
 * module: String,
 * key: String,
 * }} fieldData Object that shall contain all the information regarding a field.
 * @param {'CREATE'|'UPDATE'} mode (Optional) Mode of request can either be 'CREATE' or 'UPDATE'. Default value is 'CREATE'.
 * @returns Returns result.
 */
const createOrUpdateFieldAsync = async (fieldData, mode='CREATE') => {
  const restApiClient = RestApiClient.getInstance();
  const response = await restApiClient.sendSmartRequestAsync({
    routeName: mode === 'UPDATE' ? 'updateDynamicField' : 'createDynamicField',
    data: fieldData,
  });

  return response;
};

export const CmsCreateOrUpdateField = ({ contentId, moduleId, moduleName, moduleKeyPrefix, fieldId, fieldKey, fieldLabel, fieldType, mode, onFormSubmit, onResponse, onCancel, }) => {
  // removes unnecessary whitespaces...
  const _moduleId = StringUtilities.getDefaultIfUndefinedOrNullOrEmpty(moduleId, undefined, true);
  const _fieldId = StringUtilities.getDefaultIfUndefinedOrNullOrEmpty(fieldId, undefined, true);

  const [view, setView] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [fieldData, setFieldData] = useState({
    moduleId: _moduleId,
    dynamicFieldId: _fieldId,
    key: fieldKey,
    label: fieldLabel,
    inputType: fieldType,
  });

  const updateFieldData = entry => {
    setFieldData({
      ...fieldData,
      ...entry,
    });
  };

  const onInputValueChanged = event => {
    updateFieldData({
      [event.target.name]: event.target.value,
    });
  };

  const onFormSubmittedAsync = async event => {
    event.preventDefault();
    setLoading(true);
    setMessage('');

    const formData = new FormData(event.target);

    // adds module key as prefix if doesn't already exist in key...
    if (!fieldData.key.startsWith(moduleKeyPrefix)) {
      formData.set('key', `${moduleKeyPrefix}${fieldData.key}`);
    }

    // adds module name as prefix if doesn't already exist in label...
    if (!fieldData.label.startsWith(moduleName)) {
      formData.set('label', `${moduleName} ${fieldData.label}`);
    }

    // if 'onFormSubmit' is a function, we shall execute it...
    if (typeof onFormSubmit === 'function') {
      await onFormSubmit({
        contentId, moduleId: _moduleId, fieldId: _fieldId, mode, formData, event,
      });
    }

    createOrUpdateFieldAsync(formData, mode)
      .then(async response => {
        setLoading(false);

        response.status === 200 && setMessage(`CMS field ${_moduleId.length ? 'update' : 'create'}d successfully.`);
        response.status !== 200 && setMessage(`An error occurred while ${_moduleId.length ? 'updating' : 'creating'} CMS field.`);

        // if 'onResponse' is a function, we shall execute it...
        if (typeof onResponse === 'function') {
          await onResponse({
            contentId, moduleId: _moduleId, fieldId: _fieldId,
            mode, formData, event, response
          });
        }
      });
  };

  useEffect(() => {
    const _moduleId = StringUtilities.getDefaultIfUndefinedOrNullOrEmpty(moduleId, undefined, true);
    const _fieldId = StringUtilities.getDefaultIfUndefinedOrNullOrEmpty(fieldId, undefined, true);

    setFieldData({
      fieldId: _fieldId,
      module: _moduleId,
      key: fieldKey,
      label: fieldLabel,
      inputType: fieldType,
    });
  }, [contentId, moduleId, moduleKeyPrefix, fieldId, fieldKey, fieldLabel, fieldType, mode]);

  return <div className={Styles.main}>
    <div className={Styles.header}>
      <h3>{mode === 'UPDATE' ? 'Update' : 'Create'} CMS Field</h3>
      {view ? <KeyboardArrowUpIcon className={Styles.icon} onClick={()=>{setView(!view)}}/> : <KeyboardArrowDownIcon className={Styles.icon} onClick={()=>{setView(!view)}}/>}
    </div>
    
    <div className={Styles.container} style={!view ? {display: "none"} :{}}>
      {isLoading && <p>Please wait...</p>}
      {!isLoading && message.length ? <p>{message}</p> : null}
      {!isLoading && <form autoComplete='off' onSubmit={onFormSubmittedAsync}>
        <input type="hidden" name="contentId" value={contentId ?? ''} readOnly />
        <input type="hidden" name="moduleId" value={_moduleId} readOnly />
        <input type="hidden" name="dynamicFieldId" value={_fieldId} readOnly />
        <input type="hidden" name="fieldFor" value='contents' readOnly />
        <div className={Styles.box}>
          <label htmlFor="label">Field type</label>
          <select name="inputType"
              value={fieldData.inputType ?? 'SINGLELINE_TEXT'}
              onChange={onInputValueChanged}>
            <option value="NUMERIC">Numeric</option>
            <option value="SINGLELINE_TEXT">Singleline Text</option>
            <option value="MULTILINE_TEXT">Multiline Text</option>
            <option value="MULTILINE_RICH_TEXT">Multiline Text (Rich)</option>
            <option value="IMAGE_URL">Image URL</option>
          </select>
        </div>
        
        <div className={Styles.box}>
          <label htmlFor='label'>Field Label</label>
          <input type='text' name='label' value={fieldData.label ?? ''}
            placeholder='Enter a label for the field'
            onChange={onInputValueChanged}
            required />
        </div>
       
        <div className={Styles.box}> 
          <label htmlFor='key'>Field Key</label>
          <input type='text' name='key' value={fieldData.key ?? ''}
            placeholder='Enter a key for the field'
            onChange={onInputValueChanged}
            required />
        </div>
         
        <div className={Styles.btnContainer}>
            <button className={Styles.createOrUpdateBtn} type='submit'>{mode === 'UPDATE' ? 'Update' : 'Create'}</button>
            {mode === 'UPDATE' && <button className={Styles.createOrUpdateBtn} type='button' onClick={onCancel}>Cancel</button>}
        </div>
    </form>}
    </div>
  </div>;
};
